import * as meta from "../AppType";
import { ColorComponents, getRgb } from "./color";

type FontWeight = string | number;

export const configBase = "https://salesys-config.s3.eu-north-1.amazonaws.com";

/**
 * Each property must be prefixed with "color", "padding", or another CSS property.
 */
export class Theme {
  //  App background.
  readonly colorBackground1: string;

  //  Modal backgrounds.
  //  Element backgrounds.
  readonly colorBackground2: string;

  //  Borders.
  //  Element hover.
  //  Elements in modals.
  readonly colorBackground3: string;

  //  Borders on hover.
  //  Hard borders (at the time of writing, only text inputs).
  //  Element :active
  readonly colorBackground4: string;

  readonly colorBackgroundTransparent1: string;
  readonly colorPrimary: string;
  readonly colorPrimaryHover: string;
  /**
   * Adds a little color to icons. Defaults to the primary color.
   */
  readonly colorAccent: string;
  readonly colorDanger: string;
  readonly colorButtonBackgroundNormal: string;
  readonly colorButtonBackgroundDanger: string;
  readonly colorButtonBackgroundDimmed: string;
  readonly colorButtonNormal: string;
  readonly colorButtonSimple: string;
  readonly colorElementHover: string;
  readonly colorElementActive: string;
  readonly colorInputBackground: string;

  /**
   * @deprecated
   */
  readonly colorCplBackground: string; // Client procedure list.

  /**
   * @deprecated
   */
  readonly colorCplBorder: string;

  //  Important, large, most contrasted text.
  readonly colorText1: string;

  //  Standard text on app background.
  readonly colorText2: string;

  //  Labels.
  //  Links.
  //  Subtitles (on app background, in modals/popovers).
  //  Standard text in modals/popovers.
  //  Brighter icons.
  //  Dimmed text/buttons.
  readonly colorText3: string;

  //  Placeholders.
  //  Separators.
  //  Darker icons.
  //  Disabled labels/text.
  readonly colorText4: string;

  readonly colorGood: string;

  //  Neutral statuses.
  //  Warning statuses.
  readonly colorNeutral: string;
  readonly fontFamilyDefault: string;
  readonly fontWeightDefault: FontWeight;
  readonly fontWeightSignWidget?: FontWeight;
  /**
   * Keep in mind that the button height is 42px.
   */
  readonly borderRadiusButton: number;

  /**
   * Custom URLs to download custom fonts.
   */
  readonly fontFamilyUrls?: {
    readonly default: string;
  };
  readonly paddingButtonNormalHorizontal: number;
  /**
   * Replacement text.
   * 
   * Examples in sv, in order:
   * 0: "Klicka för att signera"
   * 1: "Tryck för att signera"
   * 2: "Klicka för att godkänna"
   * 3: "Tryck för att godkänna"
   * 4: "Godkänn"
   * 5: "Signera med BankID"
   * 6: "Signera"
   */
  readonly textOfferSign: string[] = [];

  /**
   * Optional name of the offer sign button icon.
   */
  readonly iconNameOfferSign?: string;
  readonly name?: string;

  /**
   * Note that a similar light / dark theme can share ID.
   */
  readonly id?: string;
  readonly isDark?: boolean;

  /**
   * Use this to extend an existing theme.
   */
  readonly constructorArgs: DeepPartial<Theme>;

  /**
   * 1: Border on each edge.
   */
  readonly inputStyle?: 1;

  constructor(deriveFrom: DeepPartial<Theme>) {
    Object.assign(this, {
      //  Fall-back to default variables.
      //  The way CSS handles variables, it is easiest to fall-back to default theme variables.
      ...defaultThemeParams,
      ...deriveFrom,
    });

    this.constructorArgs = deriveFrom;

    this.colorPrimaryHover = this.colorPrimaryHover || this.colorPrimary;

    //  List fallbacks here.
    this.colorButtonSimple = this.colorButtonSimple || this.colorPrimary;
    this.colorButtonNormal = this.colorButtonNormal || this.colorButtonSimple;

    this.colorButtonBackgroundDimmed = this.colorButtonBackgroundDimmed || this.colorButtonBackgroundNormal;
    this.colorButtonBackgroundDanger = this.colorButtonBackgroundDanger || this.colorButtonBackgroundDanger;

    this.colorBackground2 = this.colorBackground2 || this.colorBackground1;

    this.colorAccent = this.colorAccent || this.colorPrimary;

    if (!this.colorElementActive) {
      try {
        const { blue, green, red, alpha } = getRgb(this.colorElementHover);
        this.colorElementActive = `rgba(${red}, ${green}, ${blue}, ${alpha * 2})`;
      } catch (error) {
        console.error(error);
      }
    }
  }
}

const defaultThemeParams: DeepPartial<Theme> = {
  id: "default",
  colorBackground1: "#fff",
  colorBackground2: "#fafafa",
  colorBackground3: "#eee",
  colorBackground4: "#bdbdbd",
  colorPrimary: "#1565C0",
  colorDanger: "#E53935",
  colorButtonBackgroundNormal: "transparent",
  colorButtonBackgroundDanger: "transparent",
  colorButtonBackgroundDimmed: "transparent",
  colorInputBackground: 'transparent',
  colorCplBorder: "rgba(20, 20, 20, 0.035)",
  colorCplBackground: "#fafafa",
  colorElementHover: "rgba(20, 20, 20, 0.035)",
  colorText1: "#212121",
  colorText2: "#616161",
  colorText3: "#757575",
  colorText4: "#9e9e9e",
  colorGood: "#43A047",
  colorNeutral: "#F4511E",
  fontFamilyDefault: "Nunito, sans-serif",
  fontWeightDefault: "normal",
  fontWeightSignWidget: "600",
  borderRadiusButton: 8,
  //  Slightly less padding then background is transparent.
  paddingButtonNormalHorizontal: 10,
};

export const defaultTheme = new Theme({});

function createElementInHead(tagName: keyof HTMLElementTagNameMap, id: string) {
  const element = document.getElementById(id) || document.createElement(tagName);
  element.id = id;

  element.remove();
  document.head.appendChild(element);

  return element;
}

type SetThemeFromHostnameOptions = {
  /**
   * Stops if the theme has already been set.
   */
  unlessAlreadySet?: boolean;
}

/**
 * Looks up a theme from the current hostname and sets it as the current theme.
 */
export async function setThemeFromHostname(hostname = meta.hostname, options?: SetThemeFromHostnameOptions): Promise<Theme> {
  try {
    const themeData = await (await fetch(new URL(`/themes/${hostname}.json`, configBase).href)).json();
    if (!themeData || typeof themeData !== 'object' || Array.isArray(themeData)) {
      console.error("Custom theme for %s is not an object:", hostname, themeData);

      if (options?.unlessAlreadySet && getCurrentTheme()) {
        return getCurrentTheme();
      }

      setTheme(defaultTheme);
      return defaultTheme;
    }

    if (options?.unlessAlreadySet && getCurrentTheme()) {
      return getCurrentTheme();
    } else if (themeData.base) {
      return await setThemeFromHostname(themeData.base, options);
    }

    const theme = new Theme(themeData);

    if (options?.unlessAlreadySet && getCurrentTheme()) {
      return getCurrentTheme();
    }

    setTheme(theme);
    return theme;
  } catch (error) {
    if (options?.unlessAlreadySet && getCurrentTheme()) {
      return getCurrentTheme();
    }

    console.log("No custom theme for %s, using default theme", hostname);

    setTheme(defaultTheme)
    return defaultTheme;
  }
}

var currentTheme: Theme | null;

export function getCurrentTheme(): Theme | null {
  return currentTheme;
}

type CssVariable = { name: string, value: string };

/**
 * Sets the current theme.
 * @private Should be called from an auto-magic function, such as setThemeFromHostname.
 */
export function setTheme(theme: Theme, selector = ':root') {
  currentTheme = theme;

  const variables: CssVariable[] = Object.entries(theme).filter(([key]) =>
    //  Don't make CSS properties from variables such as `id` or `name`.
    /^color|padding|fontWeight|fontFamily|borderRadius|/.test(key)
  ).map(([_key, value]) => {
    const key = _key as keyof Theme;

    //  "colorBackground50" => "--color-background-50"
    const cssVariableName = '--' + key.replace(/([A-Z|\d])(\d+|\w)?/g, ' $1$2').replace(/\s/g, '-').toLowerCase();

    if (key === 'borderRadiusButton' || key === 'paddingButtonNormalHorizontal') {
      value += 'px';
    }

    return { name: cssVariableName, value };
  });

  if (theme.isDark) {
    //  Enables dark scroll bars, and more.
    //  See https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme#examples
    variables.push({ name: 'color-scheme', value: 'dark' });
  }

  const rgbVariables = variables.reduce((variables, variable) => {
    let rgb: ColorComponents;

    try {
      rgb = getRgb(variable.value);
    } catch (error) {
      //  Not a color value.
    }


    if (rgb) {
      //  Convert hex values to rgb to fix this:
      //  rgba(var(--color-text-1), 0)
      //  Should be this instead:
      //  rgba(var(--color-text-1-rgb), 0)
      //  Also works with rgb()
      variables.push({
        name: variable.name + '-rgb',
        value: `${rgb.red}, ${rgb.green}, ${rgb.blue}`
      });
    }

    return [...variables, variable];
  }, [] as CssVariable[]);

  createElementInHead('style', '__theme-' + selector.replace(/[^\w]/g, '')).innerHTML = `${selector} {
    ${rgbVariables.map(variable => `${variable.name}: ${variable.value};`).join('\n')}
  }`;

  for (const name in theme.fontFamilyUrls || {}) {
    const url = theme.fontFamilyUrls[name];
    if (typeof url !== 'string') {
      continue;
    }

    const element = createElementInHead('link', 'font-' + name);
    element.setAttribute('href', url);
    element.setAttribute('rel', 'stylesheet');
  }
}