import { useEffect, useRef, useState } from "react";

/**
 * A useState wrapper which gets and saves a boolean value to localStorage. 
 */
export const useLocalStorageBooleanState = (key: string) => {
  const state = useState(localStorage.getItem(key) === 'yes');

  useEffect(() => {
    localStorage.setItem(key, state[0] ? 'yes' : 'no');
  }, [state[0]]);

  return state;
}


/**
 * A useState wrapper which gets and saves a boolean value to localStorage. 
 */
export const useLocalStorageStringState = (key: string) => {
  const state = useState<string>(localStorage.getItem(key));

  useEffect(() => {
    if (state[0]) {
      localStorage.setItem(key, state[0]);
    } else {
      localStorage.removeItem(key);
    }
  }, [state[0]]);

  return state;
}

/**
 * A useState wrapper which gets and saves an object value to localStorage. 
 */
export const useLocalStorageObjectState = <T extends object = any>(key: string, defaultValue: T = <any>{}, cls?: { new(params: Partial<T>): T }) => {
  const state = useState<T>(() => {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        const object = JSON.parse(value);
        if (cls) {
          try {
            return new cls(object);
          } catch (error) {
            console.warn("Local storage state %s (%s) could not be instantiated as %s", object, key, cls, error);
          }
        }

        return object;
      } catch (error) {
        console.warn("Local storage state %s (%s) is invalid JSON", value, key, error);
      }
    }

    return defaultValue;
  });

  const json = state[0] ? JSON.stringify(state[0]) : null;
  useEffect(() => {
    if (json) {
      localStorage.setItem(key, json);
    } else {
      localStorage.removeItem(key);
    }
  }, [json]);

  return state;
}

const initialPrevious = {};

/**
 * A useEffect substitute, which fires the effect immediately on the same render cycle,
 * where as a useEffect would fire the effect on the next cycle.
 */
export const useImmediateEffect = (dependency: any, onChange: () => void) => {
  const previous = useRef(initialPrevious); //  Using null or undefined would likely result in a bug if `dependency` is the same value.

  if (previous.current === initialPrevious || previous.current !== dependency) {
    onChange();

    previous.current = dependency;
  }
}