import Cookie from 'js-cookie';

/**
 * ID of a null user.
 */
export const privateUserId = "5f4d72e00000000000000000";

const accessTokenKey = 's2_utoken'
const userIdKey = 's2_uid'
const usernameKey = 's2_uname'

/**
 * @returns The stored access token.
 */
export function getAccessToken() {
  var token = Cookie.get(accessTokenKey);
  return token
}

export function getUserId() {
  var userId = Cookie.get(userIdKey);
  return userId
}

/**
 * @returns ID of a user receiving support.
 */
export function getSupportClientUserId() {
  return Cookie.get("s2_supportClientUserId");
}

/**
 * @returns Whether the current user is logged in as support.
 */
export function isSupportLogin() {
  const supportClientUserId = getSupportClientUserId();
  return supportClientUserId && supportClientUserId === getUserId();
}

export function setUsername(username: string) {
  return Cookie.set(usernameKey, username);
}

/**
 * Stores an access token.
 */
export function useAccessToken(accessToken: string) {
  if (!accessToken) {
    Cookie.remove(accessTokenKey)
  } else {
    Cookie.set(accessTokenKey, accessToken);
  }
}

/**
 * Clears stored user credentials (logs out.)
 */
export function clearUserCredentials() {
  Cookie.remove("s2_supportClientUserId");
  Cookie.remove("s2_supportAgentUserId");
  Cookie.remove(accessTokenKey);
  Cookie.remove(userIdKey);
  Cookie.remove(usernameKey);
}