export function isUpperCase(ch: string) {
  return ch.toUpperCase() == ch;
}

/**
 * Returns an instance after update.
 */
export function applyUpdate<T>(update: Update<T> | DeepPartialUpdate<T>, original: T, merge?: (update: Partial<T>) => T): T {
  if (typeof update === 'function') {
    const next = (update as any)(original);

    if (next === false) {
      //  No update should be made.
      return original;
    }

    update = next;
  }

  if (Array.isArray(update)) {
    //  How do we merge arrays?
    return update as T;
  }

  if (typeof merge === 'function') {
    return merge({ ...original, ...update });
  }

  return { ...original, ...update };
}

/**
 * Awaits `work`, but does not resolve until a time of atleast `minTime` has passed.
 * @param minTime Minimum execution time, in milliseconds.
 */
export async function throttle<T>(work: Promise<T>, minTime: number): Promise<T> {
  return (await Promise.all([work, sleep(minTime)]))[0];
}

export function clamp(min: number, max: number, val: number) {
  return Math.max(min, Math.min(max, val));
}

/**
 * Can be used to detect empty query.
 * @param obj Object to inspect.
 * @returns Whether `obj` has any true-ish property, or an array with elements.
 */
export function hasValues(obj: any) {
  if (!obj) {
    return false;
  }

  for (let key in obj) {
    if (Array.isArray(obj[key]) && !obj[key].length) {
      continue;
    }

    if (obj[key]) {
      return true;
    }
  }

  return false;
}

export function hasElements(array: any[]) {
  if (!Array.isArray(array)) {
    return false;
  }

  return array.length > 0;
}

/**
 * Invokes the `String.prototype.toLowerCase` and `String.prototype.trim`. Handles null or non-string argument.
 * Useful for checking existing items.
 */
export function trimmedLowerCase(arg: string) {
  if (!arg || typeof arg !== 'string') {
    return arg;
  }
  return arg.toLowerCase().trim();
}

export function toggle<T>(array: T[], value: T, comparator: (value: T, b: T) => boolean = (value, b) => value === b): T[] {
  if (!array?.length) {
    return [value];
  }

  const existsAtIndex = array.findIndex(b => comparator(value, b));
  let mutated = [...array];
  if (existsAtIndex !== -1) {
    mutated.splice(existsAtIndex, 1);
    return mutated;
  }
  return [...mutated, value];
}

export function replace<T>(
  array: T[],
  replace: T,
  fallback: 'push' | 'pushStart' | 'none' = 'none',
  comparator?: ((a: { id?: any }, replace: T) => boolean) | keyof T
): T[] {
  let mutated = array?.length ? [...array] : []

  let comparedProperty = 'id';

  if (typeof comparator == 'string') {
    comparedProperty = comparator;
  }

  const i = mutated.findIndex(e => (
    typeof comparator === "function" ? comparator(e, replace) : e[comparedProperty] === replace[comparedProperty])
  );

  if (i !== -1) {
    mutated[i] = replace;
  } else if (fallback === "push") {
    mutated.push(replace);
  } else if (fallback === "pushStart") {
    mutated = [replace, ...mutated];
  }

  return mutated;
}

/**
 * Compares two arrays.
 * @returns Whether the arrays are equal.
 */
export function arrayEqual<T>(arr1: T[], arr2: T[]) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length != arr2.length) {
    return false;
  }

  for (let i = 0, n = arr1.length; i < n; i++) {
    if (arr1[i] != arr2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Checks equality by comparing the stringified value.
 * @returns Whether two stringified representations are equal.
 */
export function eq(one: any, theOther: any) {
  return JSON.stringify(one) === JSON.stringify(theOther);
}

/**
 * Uses `eq` to compare values present in `partial`.
 */
export function partialEq<T>(partial: Partial<T>, target: T) {
  for (let key in partial) {
    if (!eq(partial[key], target[key])) {
      return false;
    }
  }

  return true;
}

/**
 * Checks equality by comparing specific properties of two objects.
 */
export function propsEq<T>(one: T, theOther: T, properties: (keyof T)[]) {
  for (let prop of properties) {
    if (one[prop] !== theOther[prop]) {
      return false;
    }
  }

  return true;
}

export function unique<T>(array: T[]): T[] {
  return [...new Set(array)];
}

export function map<T>(length: number, cb: (index: number) => T): T[] {
  let result: T[] = [];

  for (let i = 0; i < length; i++) {
    result.push(cb(i));
  }

  return result;
}

/**
 * @returns Whether any shallow-level property evaluates to true.
 */
export function hasTruthyProperty(obj: any) {
  if (!obj || typeof obj != 'object') {
    return false;
  }

  for (let key in obj) {
    if (obj[key]) {
      return true;
    }
  }

  return false;
}

/**
 * Extracts coordinates from either a touch or mouse event.
 */
export function getClientXY(ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) {
  let clientX = 0, clientY = 0

  if (ev) {
    if ("clientX" in ev) {
      clientX = ev.clientX;
      clientY = ev.clientY;
    } else {
      let touch = ev.touches.item(0)
      if (touch) {
        clientX = touch.clientX
        clientY = touch.clientY
      }
    }
  }

  return {
    clientX,
    clientY
  }
}

/** Event for both mouse & touch. */
export type DivPressEvent = React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>

export function mergeFormData(...data: FormData[]): FormData {
  if (!data || !data.length) {
    return null;
  }

  if (data.length == 1) {
    return data[0]
  }

  let fd = new FormData()

  for (let d of data) {
    let entries = d.entries();
    let entry: IteratorResult<[string, FormDataEntryValue]>;
    while (true) {
      entry = entries.next();

      if (entry == null || entry.done) {
        break;
      }

      fd.set(entry.value[0], entry.value[1])
    }
  }

  return fd
}

/**
 * @returns Whether a point intersects a rectangle
 */
export function intersects(point: { x: number, y: number }, dst: { left: number, top: number, right: number, bottom: number }) {
  return point.x > dst.left && point.x < dst.right && point.y > dst.top && point.y < dst.bottom;
}

/**
 * @returns Whether to dates overlap.
 */
export function overlaps(xStart: Date, xEnd: Date, yStart: Date, yEnd: Date) {
  if (!xStart || !xEnd || !yStart || !yEnd) {
    return false;
  }

  if (xStart.getTime() == yStart.getTime() && xEnd.getTime() == yEnd.getTime()) {
    return true;
  }

  if (xStart.getTime() < yStart.getTime()) {
    return xEnd.getTime() > yStart.getTime();
  }

  if (xStart.getTime() > yStart.getTime()) {
    return xStart.getTime() < yEnd.getTime();
  }

  return false;
}

export function isEnterKey(ev: React.KeyboardEvent) {
  return [13].indexOf(ev.keyCode) != -1;
}

export function isEscapeKey(ev: React.KeyboardEvent) {
  return [27].indexOf(ev.keyCode) != -1;
}


export function isTabKey(ev: React.KeyboardEvent) {
  return [9].indexOf(ev.keyCode) != -1;
}

/**
 * Pads a string, shorthand for padStart.
 */
export function pad(str: string | number, length: number = 2, fill: string = "0") {
  return str.toString().padStart(length, fill);
}

/**
 * Pre-fetches an image.
 * @see https://stackoverflow.com/questions/3646036/javascript-preloading-images
 */
export function preFetchImage(src: string) {
  var img = new Image();
  img.src = src;
}

export function isDateValid(d: Date) {
  return d instanceof Date && !isNaN(d as any)
}

export function nullIfNaN<T extends string | number>(val: T): T {
  return isNaN(val as any) ? null : val;
}

export function numberOrNull<T extends string | number>(value: T): number {
  if (isNaN(value as any)) {
    return null;
  }

  if (typeof value === 'number') {
    return value;
  }
  
  return parseFloat(value);
}

/**
 * Pushes to an array.
 */
export function push<T>(array: T[], element: T) {
  if (!array || !Array.isArray(array)) {
    return [element];
  }

  return [...array, element];
}

export function srcFromFile(file: File | Blob): Promise<string> {
  return new Promise(resolve => {
    let r = new FileReader();
    r.onload = () => {
      resolve(r.result as string)
    }
    r.readAsDataURL(file)
  })
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function ensureArray<T>(obj: T) {
  return Array.isArray(obj) ? obj : [obj]
}

export function notNull(value: any) {
  return value != null;
}

/**
 * @returns Whether any shallow value in an `object` is a string.
 */
export function anyStringInObject(object: any) {
  if (!object || typeof object !== 'object') {
    return false;
  }

  return Object.values(object).find(v => typeof v === 'string') != null;
}

/**
 * Converts the extension to a mime type.
 */
export function getMimeTypeFromUrl(url: string) {
  if (/\.jpe?g/i.test(url)) {
    return 'image/jpeg';
  }
  if (/\.png/i.test(url)) {
    return 'image/png';
  }
  return ""
}

/**
 * Helper function for downloading an image as base64.
 * Skips origin checking.
 */
export function toDataURL(url: string, mimeType = getMimeTypeFromUrl(url), opts: Partial<{
  returnMetadata: boolean
}> = {}): Promise<string | { width: number; height: number; ratio: number; dataUrl: string; }> {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.onerror = function () {
      return reject(...arguments)
    }
    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      var ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.getContext('2d').drawImage(img, 0, 0);

      let dataUrl = canvas.toDataURL(mimeType)

      if (opts.returnMetadata) {
        resolve({
          height: img.naturalHeight,
          width: img.naturalWidth,
          ratio: img.naturalWidth / img.naturalHeight,
          dataUrl
        })
      } else {
        resolve(dataUrl);
      }
    }
    img.crossOrigin = "anonymous"
    img.src = url
  })
}

/**
 * @returns the new coordinates for this box to be contained within  
 */
export function contain(x: number, y: number, width: number, height: number) {

}

export function choose<T>(a: T[]) {
  return a[Math.floor(Math.random() * a.length)]
}

export function distance(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)));
}

export function distanceBetweenCenters(from: DOMRect, to: DOMRect) {
  return distance(
    from.x + from.width / 2,
    from.y + from.height / 2,
    to.x + to.width / 2,
    to.y + to.height / 2,
  );
}

export type FormatCurrencyOpts = {
  trimFractionDigits?: boolean;
  includeCurrency?: boolean;
}

/**
 * Formats to a local currency.
 */
export function formatCurrency(amount: number, locale = 'sv', opts: FormatCurrencyOpts = {}) {
  let currency = { "sv": "kr" }[locale]
  if (opts.includeCurrency === undefined) {
    opts.includeCurrency = true;
  }

  let formatted = `${(amount || 0).toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })} ${opts.includeCurrency ? currency : ''}`;

  if (opts.trimFractionDigits) {
    formatted = formatted.replace(/([,.])([1-9+])?(0+)/, '$1$2');
  }

  //  Remove trailing delimiter
  formatted = formatted.replace(/[,.]\s/, ' ');

  return formatted;
}

/**
 * Starting from the left, counts the consequent characters which are equal in both strings.
 */
export function consequtiveCharCount(a: string, b: string) {
  for (let i = 0, n = Math.min(a.length, b.length); i < n; i++) {
    if (a[i] != b[i]) {
      return i;
    }

    if (i == n - 1) {
      return n;
    }
  }

  return 0;
}

/**
 * Whether the element is an empty object, array, or false-ish.
 */
export function isEmpty(element: any) {
  if (!element) {
    return true;
  }

  if (typeof element == 'object') {
    return Object.keys(element).length == 0;
  }

  return false;
}

/**
 * Shorthand for if-this-then-that.
 * @example
 * onKeyUp={when(isEnterKey, submitForm)}
 */
export function when<T>(predicate: (target: T) => boolean, then: Function) {
  return (target: T) => {
    if (predicate(target)) {
      then?.();
    }
  }
}

/**
 * Sums a numeric array.
 */
export function sum(array: number[]) {
  return array.reduce((sum, val) => sum + val, 0);
}

/**
 * Truncates a string if its length is greater than `maxLength`
 */
export function truncate(string: string, maxLength: number, tail = '...') {
  if (typeof string != 'string' || string.length + tail.length <= maxLength) {
    return string;
  }

  return string.substr(0, maxLength - tail.length) + tail;
}

/**
 * Truncates a string to be at most `maxLength` characters long. Words will not be cut.
 */
export function truncateKeepWords(string: string, maxLength: number, tail = '...') {
  if (typeof string != 'string' || string.length + tail.length <= maxLength) {
    return string;
  }

  let result = "";
  for (const word of string.split(' ')) {
    if (result.length + word.length > maxLength) {
      return result + tail;
    }

    result += ' ' + word;
  }

  return result;
}

/**
 * Iterates through every property of an object, even nested,
 * and replaces it with the result from `callback`.
 */
export function mapObjectProperties(object: any, callback: (property: any, name: string) => any) {
  if (!object || typeof object !== 'object') {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(element => mapObjectProperties(element, callback));
  }

  object = { ...object };

  for (const key in object) {
    object[key] = callback(object[key], key);
    object[key] = mapObjectProperties(object[key], callback);
  }

  return object;
}

/**
 * Maps an array to an object using `callbackFn`.
 * @param array Source array.
 * @param callbackFn Called for each element of `array` and should return the property key and value.
 */
export function mapToObject<T, V>(array: T[], callbackFn: (element: T, index: number, existingProperty: V | null) => [string, V]): { [key: string]: V } {
  let mapped: any = {};

  for (let i = 0; i < array.length; i++) {
    const provided = callbackFn(array[i], i, mapped[i]);
    if (!provided) {
      continue;
    }
    const [key, value] = provided;
    if (!['string', 'number'].includes(typeof key)) {
      throw new Error('Invalid key type: ' + typeof key);
    }
    mapped[key] = value;
  }

  return mapped;
}

export function safeConcat(...arrays: any[]) {
  let result: any[] = [];
  for (let array of arrays) {
    result = result.concat(array || []);
  }
  return result;
}

/**
 * @returns The given string only if it is part of the enum, otherwise null, 
 * @example toEnum<MyEnum>(MyEnum, 'hello')
 */
export function toEnum<T>(targetEnum: object, value: string): T {
  if (!value) {
    return null;
  }
  return Object.values(targetEnum).includes(value) ? (value as unknown) as T : null;
}